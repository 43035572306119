import React, { useState, useEffect } from "react"
import { Collapse } from 'reactstrap';
import { win } from "@component-utility/utils"

import iconPlus from '@images/icon/icon-plus.svg';
import iconMinus from '@images/icon/icon-minus.svg';

const MobileAccordion = ({accordionData}) => {

    const [isAccordionState , setAccordionState] = useState(false)
    const hashLink = win && window.location.hash;

    useEffect(() => {
  		if (hashLink) {
  			document
  				.querySelector(`${hashLink.replace('/', '')} .video-eyebrow`)
  				.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
  		}
    }, [])

    return (
        <>
            <div 
                className='col-12 col-xl-11 info-dropdown-container mb-40 mb-sm-0'
                onClick={() => setAccordionState(!isAccordionState)}
            >
                <div className='image-container col-1 p-0'>
                    <img
                        className={`info-dropdown-image ${accordionData.icon.class}`}
                        src={accordionData.icon.image}
                        alt={accordionData.icon.alt}
                    />
                </div>
                <div className='col-11 col-xl-11 p-0 info-dropdown-text-wrapper ml-20'>
                    <p className='info-dropdown-title mb-5 mb-xs-30'>
                        {accordionData.title}
                    </p>
                    <div className='hidden-xs'>
                        {accordionData.content}
                    </div>
                    <img
                        src={isAccordionState ? iconMinus : iconPlus}
                        alt=''
                        className='plus-minus-icon hidden-sm-up'
                    />
                </div>
            </div>
            <Collapse
                    isOpen={isAccordionState || hashLink === accordionData.hashLink}
                    className='collapse-text col-12 hidden-sm-up'
                >
                    {accordionData.content}
                    
            </Collapse>
            <div className='hidden-sm-up col-12 ml-15 xs-bottom-border mb-sm-25' />
        </>
    )
    
}

export default MobileAccordion;

