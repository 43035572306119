import axios from "axios"
import config from "@config/config"

const baseParams = {
  mode: "no-cors",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
}

const decorateURLPayload = origData => {
  return Object.assign({}, origData, { URL: config.apiBaseUrl })
}

const handle500 = () => {
  if (window.location.pathname !== "/500") {
    window.location.replace("/500")
  }
}

const request = (url, params = {}) =>
axios(url, { ...baseParams, ...params }).catch(error => {
  if (error.response.status === 500) {
    handle500()
  }
  return new Promise((_, reject) => reject(error))
})


export const subscribe = data => {
  console.log(data);
  return request('/api/stay_connected_form.php', {
    method: "POST",
    data: JSON.stringify(data)
  })
    .then(({ data }) => data)
    .catch((e) => {
      console.error(e);
      return false
    })
}

export const geolocateZipcode = zipcode => {
  if (window) {
    const geocoder = new window.google.maps.Geocoder()

    return new Promise((done, reject) => {
      geocoder.geocode(
        {
          address: zipcode
        },
        (geoData, geoStatus) => {
          if (geoStatus === window.google.maps.GeocoderStatus.OK) {
            const loc = geoData[0].geometry.location
            done({
              lat: loc.lat(),
              lng: loc.lng()
            })
          } else {
            reject(false)
          }
        }
      )
    })
  }
}
